@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/fonts/cera-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/fonts/cera-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/fonts/cera-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../public/fonts/cera-bold.woff2') format('woff2');
}

.font-cera,
.font-ivy-ora {
  @apply antialiased;
}
