@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import 'font.css';
  @import 'typography.css';
  @import 'btn.css';
  @import 'input.css';
  @import 'datepicker.css';
  @import 'richtext.css';
  html {
    @apply font-cera lg:overscroll-none;
  }

  body {
    @apply font-normal will-change-auto lg:overflow-x-hidden lg:overscroll-none;
  }
}

@layer utilities {
  .container {
    @apply max-w-[1440px];
  }

  .btn {
    @apply cursor-pointer 
  font-cera 
  outline-0
  inline-flex 
  text-sm
  
  gap-2
  duration-300
  leading-6 
  items-center 
  justify-center 
  px-4 
  h-11
  text-white
  hover:text-primary
  hover:bg-white
  border
  font-medium
  relative
  overflow-hidden
  disabled:!cursor-not-allowed;

    &:not(.tertiary) {
      @apply active:!bg-highlight-sunshineYellow 
    active:!border-highlight-sunshineYellow
    active:!text-primary;
    }

    &.primary,
    &.secondary {
      @apply disabled:opacity-70;
    }

    &:only-child {
      @apply justify-center;
    }
    svg {
      @apply size-4 stroke-white duration-300;
    }
    &:hover svg {
      @apply duration-300 stroke-primary;
    }
  }

  .gradient-light {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .gradient-left-to-right {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 21%, rgba(0, 0, 0, 0.555) 38%);
  }

  .gradient-right-to-left {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 21%, rgba(180, 179, 179, 0.5551470588235294) 38%);
  }

  .flexible-width {
    flex: 1;
  }

  .gradient-center {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(180, 179, 179, 0.4551470588235294) 26%,
      rgba(180, 179, 179, 0.4551470588235294) 68%,
      rgba(255, 250, 250, 0.008928571428571397) 100%
    );
  }

  .gradient-left-and-right {
    background: linear-gradient(
      90deg,
      rgba(180, 179, 179, 0.5) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 50%,
      rgba(180, 179, 179, 0.5) 100%
    );
  }

  .gradient-bottom {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 19%, rgba(180, 179, 179, 0.8) 100%);
  }

  .transform-unset {
    transform: unset;
  }
}

.grecaptcha-badge {
  @apply hidden;
}

.tertiary-button-wrapper {
  @apply relative overflow-hidden;
}

.underline-animation.light {
  @apply w-full h-[1px] bg-white transition-all duration-500 mt-[-6px] pointer-events-none;
}

.underline-animation.dark {
  @apply w-full h-[1px] bg-primary transition-all duration-500 mt-[-6px] pointer-events-none;
}

.tertiary-button-wrapper:hover {
  .underline-animation.light,
  .underline-animation.dark {
    @apply translate-x-full opacity-0 cursor-pointer;
  }
}

.first-button-bullet-point.light,
.first-button-bullet-point.dark {
  @apply relative;
}

.first-button-bullet-point.light::after {
  content: '';
  @apply absolute w-[2px] h-[2px] bg-white rounded-full top-1/2 right-[-33px];
}
.first-button-bullet-point.dark::after {
  content: '';
  @apply absolute w-[2px] h-[2px] bg-primary rounded-full top-1/2 right-[-33px];
}

.text-with-bullet.light::before {
  content: '';
  @apply absolute w-[3px] h-[3px] top-1/2 -translate-y-1/2 left-0 bg-white rounded-full;
}

.text-with-bullet.dark::before {
  content: '';
  @apply absolute w-[3px] h-[3px] top-1/2 -translate-y-1/2 left-0 bg-primary rounded-full;
}

.height-transition * {
  transition:
    font-size 0.25s,
    margin 0.25s,
    padding 0.25s,
    opacity 1s 0.25s;
}
.height-transition.hide * {
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  /* fade out, then shrink */
  transition:
    opacity 0.25s,
    font-size 0.25s,
    margin 1s 0.25s,
    padding 1s 0.25s;
}

.buttonAnim {
  @apply lg:opacity-0 lg:transition-all lg:duration-500;
}

.MediaCarouselFullBleedModule:hover {
  .buttonAnim {
    @apply lg:transition-all lg:duration-500 lg:opacity-100;
  }
}

/* ScrollbarStyles.css */

/* Width and height for the scrollbar track */
.custom-scroll-bar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track (background) */
.custom-scroll-bar::-webkit-scrollbar-track {
  background: #b1b3b7;
}

/* Handle (thumb) */
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #182a43;
}

/* Handle on hover */
.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #182a43;
}

.image-credit {
  @apply absolute right-0 bottom-0 min-h-8 pt-[7px] pb-1 px-2 bg-black/50 font-cera text-secondary-warmsand/75 text-paragraph-s;
}

.underline-tab {
  @apply absolute bottom-[-8px] left-0 right-0 h-[2px];
}
