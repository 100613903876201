/* Datepicker */

.react-datepicker {
  @apply p-4 flex relative bg-[#eae6e2] rounded-none w-full h-[362px] m-auto;
}

.react-datepicker__header {
  @apply text-center relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-primary  font-cera text-paragraph-s not-italic font-normal leading-16;
}

.react-datepicker__month-container {
  @apply w-full;
}

.react-datepicker__header--custom > div.relative {
  @apply items-center h-[30px];
}

.react-datepicker__header--custom > div.relative .react-datepicker__current-month {
  @apply text-base;
}

.react-datepicker__month {
  @apply flex flex-col gap-2.5;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex gap-0 whitespace-nowrap justify-between text-paragraph-s not-italic font-normal leading-16;
}

.react-datepicker__day-names {
  @apply mt-4 mb-3;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-primary  flex justify-center items-center w-8 lg:w-full font-cera text-paragraph-s not-italic font-normal leading-16 uppercase;
}

.react-datepicker__day-name {
  @apply text-paragraph-s not-italic font-normal leading-16;
}

.react-datepicker__day--outside-month {
  @apply text-black/25;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  @apply cursor-pointer;
}
.react-datepicker__day:hover:not(.react-datepicker__day--selecting-range-end),
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-highlight-sunshineYellow/75  rounded-full;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  @apply bg-highlight-sunshineYellow w-6 h-6 pb-3 px-3 pt-[9px] rounded-full text-black;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  @apply bg-highlight-sunshineYellow/50;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  @apply cursor-default text-black/25;
}

.react-datepicker__day--disabled.react-datepicker__day--today,
.react-datepicker__day--today.react-datepicker__day {
}
.react-datepicker__week > div {
  @apply w-[42px] h-[38px];
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  @apply bg-transparent;
}

.react-datepicker__input-container {
  @apply rounded-sm w-full flex flex-row-reverse h-10 items-center
  px-4 py-3 border-b border-white/25;
}

.react-datepicker__aria-live {
  @apply absolute h-px overflow-hidden w-px whitespace-nowrap -m-px p-0 border-0;
  clip-path: circle(0);
}

.react-datepicker__week .react-datepicker__day--selecting-range-start {
  @apply rounded-l-full bg-highlight-sunshineYellow;
}
.react-datepicker__week .react-datepicker__day--selecting-range-end {
  @apply rounded-r-full bg-highlight-sunshineYellow;
}
