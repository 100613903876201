.input-group {
  @apply flex flex-col my-2 first:mt-0 last:mb-0 only:my-0 relative;
}

.input-group .textPlaceholder,
.input-group .textareaPlaceholder,
.input-group .popover-title {
  @apply absolute left-[12px] top-[12px] transition-all duration-200 ease-in-out pointer-events-none  opacity-100;
}

.input-group .input {
  @apply text-tag-m not-italic font-normal leading-24 rounded-none;
}

.input-group .input {
  @apply w-full p-2 block bg-transparent transition-all duration-200 ease-in-out  outline-none;
}

.input-dark .input {
  @apply border-b border-primary/10 disabled:text-black/30;
}

.input-light .input {
  @apply border-b border-secondary-coolsand/10;
}

.input-dark .input:focus {
  @apply border-primary/75;
}

.input-light .input:focus {
  @apply border-secondary-coolsand/75;
}

.input-group .input[type='text']:focus ~ .textPlaceholder,
.input-group .input[type='text']:not(:placeholder-shown) ~ .textPlaceholder,
.input-group .input[type='textarea']:focus ~ .textareaPlaceholder,
.input-group .input[type='textarea']:not(:placeholder-shown) ~ .textareaPlaceholder,
.input-group .open .popover-title {
  @apply -top-[3px] left-2 text-tag-s not-italic font-normal leading-16 tracking-[-0.2px]  opacity-50;
}

.input-group .input.selected .popover-title {
  @apply opacity-100;
}

/* .input-group .input[type='text']::placeholder,
.input-group .input[type='textarea']::placeholder {
  @apply text-red-500;
} */

.input-group .checkbox-wrapper {
  @apply flex  gap-2  text-center mt-8 lg:mt-8 lg:max-w-[724px] lg:mx-auto;
}

.input-group.field-dataConsent .error-message {
  @apply lg:w-[724px] lg:mx-auto;
}

.input-group.field-yourMessage {
  @apply mt-4;
}

.input-group.field-yourMessage textarea {
  @apply mt-3 pt-0;
}

.input-group .radio {
  @apply flex;
}

.input-group .checkbox-wrapper .label {
  @apply mb-0 normal-case font-cera text-paragraph-s transition-all duration-200 ease-in-out not-italic font-normal leading-16  w-full;
}

.input-group .radio .label {
  @apply mb-0 normal-case font-normal;
}

.input-dark .input[type='checkbox'],
.input-dark .input[type='radio'],
.input-light .input[type='checkbox'],
.input-light .input[type='radio'] {
  @apply appearance-none relative;
}

/* .input-group .input[type='checkbox']:checked ~ label,
.input-group .input[type='radio']:checked ~ label {
  @apply opacity-100;
} */

.input-dark .input[type='checkbox'] {
  @apply w-4 h-4 p-1 bg-clip-content  border border-solid border-primary;
}
.input-dark .input[type='checkbox']:checked:before {
  content: '';
  @apply absolute w-[5px] h-[10px] border rotate-45 border-solid border-primary border-t-0 border-r-[1px] border-b-[1px] border-l-0 left-1 top-px;
}

.input-light .input[type='checkbox'] {
  @apply w-4 h-4 p-1 bg-clip-content  border border-solid border-secondary-coolsand;
}
.input-light .input[type='checkbox']:checked:before {
  content: '';
  @apply absolute w-[5px] h-[10px] border rotate-45 border-solid border-secondary-coolsand border-t-0 border-r-[1px] border-b-[1px] border-l-0 left-1 top-px;
}

.input-dark .input[type='radio'] {
  @apply w-4 h-4 p-1 bg-clip-content rounded-full border border-solid border-primary;
}

.input-dark .input[type='radio']:checked {
  @apply bg-primary;
}

.input-light .input[type='radio'] {
  @apply w-4 h-4 p-1 bg-clip-content rounded-full border border-solid border-secondary-coolsand;
}

.input-light .input[type='radio']:checked {
  @apply bg-secondary-coolsand;
}

/* .input-group .input[type='text'] {
  @apply outline-none focus:border-black;
} */

.input-group .label {
  @apply block mb-2  text-[0.875rem] leading-[1.25rem] lg:text-[1rem] lg:leading-[1.5rem] font-semibold tracking-[0.01em];
}

.input-group .multi-checkbox {
  @apply flex items-center;
}

.input-group .input[type='text']::placeholder,
.input-group .input[type='textarea']::placeholder {
  @apply opacity-0;
}

/* Dropdown */
.input-group .datepicker,
.input-group .dropdown {
  @apply border-none p-0 w-full md:w-[262px];
}

.input-group .datepicker .arrow {
  @apply hidden;
}

.input-group .datepickerPlaceholder {
  @apply hidden;
}

.input-group .datepicker .main-button,
.input-group .dropdown .main-button {
  @apply border-b border-primary/10  h-[46px] transition-all duration-200 ease-in-out;
}

.input-group .open.datepicker .main-button,
.input-group .open.dropdown .main-button {
  @apply border-primary/75;
}

/* .input-group .dropdown .popover-title {
  @apply text-paragraph;
} */

.dropdown .content {
  @apply border-t rounded-xl bg-primary;
}

.input-group .dropdown.open .content {
  @apply mt-1 drop-shadow-lg;
}

.input-group .dropdown.open .option {
}
/* End Dropdown */

.input-group.error .label {
}

.input-group.error .input {
}

.input-group.error .error-message {
  @apply font-cera text-paragraph-s not-italic font-normal leading-16 text-error;
}

.input-group.error.input-light .error-message {
  @apply text-errorLight;
}

.input-group .input.number-input {
  @apply flex;
}

.input-group .input.number-input input[type='number']::-webkit-outer-spin-button,
.input-group .input.number-input input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .input.number-input input[type='number'] {
  width: 80%;
  text-align: center;
  -moz-appearance: textfield;
  outline: none;
  border: none;
}

.input-group .input.number-input input[type='number']:focus-visible {
  outline: none;
  border: none;
}

.input-group .input.datepicker {
  @apply p-0;
}

.radio-group {
  @apply flex items-center gap-2  text-tag-m not-italic font-normal leading-24;
}

.input-group .radio-group,
.input-group .multi-checkbox {
  @apply cursor-pointer;
}

.input-group:has(input:disabled),
.input-group:has(input:disabled) .number-input > *,
.input-group:has(input:disabled) label:has(input:disabled),
button:disabled {
  @apply cursor-not-allowed;
}

.input-group .input.number-input input[type='number']:disabled {
  @apply bg-transparent;
}

.select {
  @apply px-4 py-2.5 bg-transparent  text-small-regular tracking-[.96px] font-medium font-cera uppercase outline-none grow;
}

.input-common {
  @apply font-cera p-2 backdrop-blur-[13px] border-white/25 rounded-full border  text-small-regular tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}

.input-common option {
  @apply rounded-full border  text-small-regular tracking-[.96px] uppercase outline-none;
}

input[type='color'] {
  @apply p-0 backdrop-blur-[13px] border-white/25 rounded-full border  text-small-regular tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  @apply backdrop-blur-[13px] border-white/25 rounded-full border  text-small-regular tracking-[.96px] uppercase outline-none;
  background: rgba(255, 255, 255, 0.02);
}

.booking-bar {
  .form-items-container {
    .content-container {
      @apply ml-[-8px] lg:ml-[8px] w-[369px] lg:w-[280px]  rounded-[4px] bg-secondary-coolsand;
      &.content-container-open {
        @apply p-[8px] bg-secondary-coolsand;
        .content {
          @apply rounded-none;
        }
      }
    }
  }
  .guests {
    .content {
      .number-input {
        @apply border-0 !w-[88px];
      }
      .button {
        @apply bg-white rounded-full w-[15px] h-[15px] p-[4px];
      }
      input {
        @apply bg-transparent;
      }
      .apply {
        @apply text-sm text-center py-4 border-b border-primary w-fit m-auto cursor-pointer select-none;
      }
    }
  }
}
